
























import { Vue, Component, Prop } from 'vue-property-decorator'
import TitleBar, { ITitleBarButton } from '@/components/Eterian/TitleBar.vue'
import CardComponent from '@/components/CardComponent.vue'
import PatientModalTable from './tables/PatientModalTable.vue'
import ModalBox from '@/components/Eterian/ModalBox.vue'
import ExamForm from '@/views/forms/ExamForm.vue'
import { IExamCreate, ExamUtils } from '@/store/interfaces/exam-model'
import { ExamStore } from '@/store/modules/exam'
import DynamicSection from '@/components/Eterian/DynamicSection.vue'

@Component({
  components: {
    CardComponent,
    TitleBar,
    ModalBox,
    PatientModalTable,
    ExamForm,
    DynamicSection,
  },
})
export default class AddExam extends Vue {
  // Properties
  @Prop(String)
  injectedPatientId: string | undefined
  @Prop(String)
  injectedPatientFullname: string | undefined

  // Data
  isModalActive = false
  form: IExamCreate = ExamUtils.createInit()

  // Computed Data
  get titleStack() {
    return ['Amministrazione', 'Esami', 'Inserisci']
  }
  get titleBarButton() {
    return {
      label: 'Torna agli Esami',
      icon: 'arrow-left',
      to: '/main/exams',
    } as ITitleBarButton
  }

  // Vue Hooks
  mounted() {
    if (this.injectedPatientId) {
      this.form.patient_id = parseInt(this.injectedPatientId)
    }
  }

  // Methods
  async onSubmit() {
    // Check if undefined field has been modified to empty string
    ExamUtils.createCleanUndefined(this.form)
    const result = await ExamStore.actionCreate(this.form)
    if (result) {
      this.$router.back()
    }
  }
  onReset() {
    this.form = ExamUtils.createInit()
  }
  onCancel() {
    if (ExamUtils.createCheckHasChanged(this.form)) {
      this.isModalActive = true
    } else {
      this.$router.back()
    }
  }
}
