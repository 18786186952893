import { BaseUtils } from './base'

export interface IReportBase {
  note?: string
  exam_id: number
}

export interface IReportCreate extends IReportBase {
  tmp_file_list: string[]
}

export interface IReportUpdate {
  note?: string
}

export interface IReport extends IReportBase {
  id: number
  download_date?: string
  deletion_date?: string
  expired: boolean
  created_at: string
  updated_at: string
}

class ReportUtilsImpl extends BaseUtils {
  createInit(examId = 10): IReportCreate {
    return {
      note: undefined,
      exam_id: examId,
      tmp_file_list: [],
    }
  }

  updateInit(obj: IReport | null): IReportUpdate {
    if (obj)
      return {
        note: obj.note,
      }
    return {}
  }
}

export const ReportUtils = new ReportUtilsImpl()
