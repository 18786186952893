

































































import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class ReportTable extends Vue {
  // Properties
  @Prop({ type: Array, required: true })
  dataIn!: Array<Record<string, any>>
  @Prop({ type: Boolean, default: false })
  checkable!: boolean
  @Prop({ type: Boolean, default: false })
  isLoading!: boolean

  // Data
  defaultSort = 'created_at'
  stickyHeader = false
  paginated = false
  perPage = 20
  checkedRows = []

  // Methods
  onDownloadButtonClick(id: number) {
    this.$emit('download-clicked', id)
  }
  onDeleteButtonClick(row: Record<string, any>) {
    this.$emit('delete-clicked', row)
  }
}
