



















import { Vue, Component } from 'vue-property-decorator'
import TitleBar, { ITitleBarButton } from '@/components/Eterian/TitleBar.vue'
import CardComponent from '@/components/CardComponent.vue'
import ModalBox from '@/components/Eterian/ModalBox.vue'
import OperatorForm from '@/views/forms/OperatorForm.vue'
import { IUserProfileCreate, UserProfileUtils } from '@/store/interfaces/user-model'
import { OperatorStore } from '@/store/modules/operator'
import DynamicSection from '@/components/Eterian/DynamicSection.vue'

@Component({
  components: {
    CardComponent,
    TitleBar,
    ModalBox,
    OperatorForm,
    DynamicSection,
  },
})
export default class AddOperator extends Vue {
  // Data
  isModalActive = false
  form: IUserProfileCreate = UserProfileUtils.createInit()

  // Computed Data
  get titleStack() {
    return ['Amministrazione', 'Operatori', 'Inserisci']
  }
  get titleBarButton() {
    return {
      label: 'Torna agli Operatori',
      icon: 'arrow-left',
      to: '/main/operators',
    } as ITitleBarButton
  }

  // Methods
  async onSubmit() {
    const result = await OperatorStore.actionCreate(this.form)
    if (result) {
      this.$router.back()
    }
  }
  onReset() {
    this.form = UserProfileUtils.createInit()
  }
  onCancel() {
    if (UserProfileUtils.createCheckHasChanged(this.form)) {
      this.isModalActive = true
    } else {
      this.$router.back()
    }
  }
}
