























































































































import { Vue, Component } from 'vue-property-decorator'
import TitleBar from '@/components/Eterian/TitleBar.vue'
import Tiles from '@/components/Eterian/Tiles.vue'
import CardWidget from '@/components/CardWidget.vue'
import HeroBarMain from '@/components/Eterian/HeroBarMain.vue'
import CardComponent from '@/components/CardComponent.vue'
import CardButton from '@/components/Eterian/CardButton.vue'
import ExamTable from '@/views/pages/tables/ExamTable.vue'
import { StatsStore } from '@/store/modules/stats'
import { ExamStore } from '@/store/modules/exam'
import { RouteNames } from '@/router/route-details'
import { UserStore } from '@/store/modules/user'
import DynamicSection from '@/components/Eterian/DynamicSection.vue'
import { SettingsStore } from '@/store/modules/settings'
import { PaymentStore } from '@/store/modules/payment'
import { IProductPlan } from '@/store/interfaces/billing-model'

@Component({
  components: {
    DynamicSection,
    HeroBarMain,
    CardComponent,
    CardButton,
    CardWidget,
    Tiles,
    TitleBar,
    ExamTable,
  },
})
export default class Dashboard extends Vue {
  // Data
  isLastExamTableLoading = false
  isNotReportedExamTableLoading = false

  // Computed Data
  get titleStack() {
    return ['Generale', 'Home']
  }
  get isAdmin() {
    return UserStore.userProfile?.is_admin
  }
  get dashboard() {
    return StatsStore.dashboard
  }
  get exams() {
    return ExamStore.data
  }
  get notReportedExams() {
    return ExamStore.notReportedExams
  }
  get settings() {
    return SettingsStore.data
  }
  get productPlanDict() {
    return PaymentStore.productPlanDict
  }
  get activePlan(): IProductPlan {
    if (
      this.settings?.plan_id &&
      (this.settings.subscription_status == 'active' || this.settings.plan_id == 'past_due')
    ) {
      const plan = this.productPlanDict[this.settings.plan_id]
      if (plan) return plan
    }
    return this.productPlanDict['free']
  }

  // Vue Hooks
  async mounted() {
    if (UserStore.userProfile?.company_id == null) return
    await this.loadDashboard()
    await this.loadExams()
    await this.loadNotReportedExams()
    await this.loadSettings()
    await this.loadProductPlan()
  }

  // Methods
  async loadDashboard() {
    await StatsStore.actionGetDashboard()
  }
  async loadExams() {
    this.isLastExamTableLoading = true
    await ExamStore.actionGetMulti({ limit: 10 })
    this.isLastExamTableLoading = false
  }
  async loadNotReportedExams() {
    this.isNotReportedExamTableLoading = true
    await ExamStore.actionGetMultiNotReported({ limit: 10 })
    this.isNotReportedExamTableLoading = false
  }
  async loadSettings() {
    await SettingsStore.actionGet()
  }
  async loadProductPlan() {
    await PaymentStore.actionGetPlans()
  }
  routeToExams(notReported = false) {
    const params = notReported ? { defaultShow: 'not_reported' } : undefined
    this.$router.push({ name: RouteNames.exams, params: params })
  }
  routeToBilling() {
    this.$router.push({ name: RouteNames.billing })
  }
}
