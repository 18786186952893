


















import { Vue, Component, Prop } from 'vue-property-decorator'
import TitleBar, { ITitleBarButton } from '@/components/Eterian/TitleBar.vue'
import CardComponent from '@/components/CardComponent.vue'
import ModalBox from '@/components/Eterian/ModalBox.vue'
import OperatorForm from '@/views/forms/OperatorForm.vue'
import { IUserProfileUpdate, UserProfileUtils } from '@/store/interfaces/user-model'
import { OperatorStore } from '@/store/modules/operator'
import DynamicSection from '@/components/Eterian/DynamicSection.vue'

@Component({
  components: {
    CardComponent,
    TitleBar,
    ModalBox,
    OperatorForm,
    DynamicSection,
  },
})
export default class UpdateOperator extends Vue {
  // Properties
  @Prop({ type: Number, required: true })
  id!: number

  // Data
  isModalActive = false

  // Computed Data
  get titleStack() {
    return ['Amministrazione', 'Operatori', 'Modifica']
  }
  get titleBarButton() {
    return {
      label: 'Torna agli Operatori',
      icon: 'arrow-left',
      to: '/main/operators',
    } as ITitleBarButton
  }
  get operator() {
    return OperatorStore.current
  }
  get form(): IUserProfileUpdate {
    return UserProfileUtils.updateInit(this.operator)
  }

  // Vue Hooks
  async mounted() {
    await this.loadOperator()
  }

  // Methods
  async loadOperator() {
    if (this.operator && this.operator.id == this.id) return
    await OperatorStore.actionGet(this.id)
  }
  async onSubmit() {
    if (!this.operator) return
    const diffObj = UserProfileUtils.updateGetDiff(this.form, this.operator)
    const result = await OperatorStore.actionUpdate({ id: this.id, obj: diffObj })
    if (result) {
      this.$router.back()
    }
  }
  onCancel() {
    if (!this.operator) return
    if (UserProfileUtils.updateCheckHasChanged(this.form, this.operator)) {
      this.isModalActive = true
    } else {
      this.$router.back()
    }
  }
}
