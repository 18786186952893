







































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { IProductPlan } from '@/store/interfaces/billing-model'
import { openCheckoutSession } from '@/store/api/admin/billing'
import ConditionalTooltipButton from '@/components/Eterian/form/ConditionalTooltipButton.vue'
import TextInfo from '@/components/Eterian/TextInfo.vue'

@Component({
  components: {
    ConditionalTooltipButton,
    TextInfo,
  },
})
export default class ProductPlanTable extends Vue {
  // Properties
  @Prop({ type: Array, required: true })
  productPlans!: IProductPlan[]

  // Data
  planDataWithSMS: IProductPlan[] = []
  selectedPlan: IProductPlan | null = null
  billedPlan: IProductPlan | null = null
  optionSmsChecked = false

  // Computed Data
  get planData() {
    const data = []
    this.planDataWithSMS = []
    for (const plan of this.productPlans) {
      if (plan.has_sms) this.planDataWithSMS.push(plan)
      else data.push(plan)
    }
    return data
  }
  get selected() {
    if (this.selectedPlan) return this.selectedPlan
    return this.planData[0]
  }
  set selected(sel: IProductPlan) {
    if (sel.id == 'free') this.selectedPlan = this.billedPlan = null
    else this.selectedPlan = sel
    if (this.optionSmsChecked) {
      const sel_index = this.planData.indexOf(sel)
      this.billedPlan = this.planDataWithSMS[sel_index - 1]
    } else this.billedPlan = sel
  }
  get isSubscribeButtonDisabled() {
    if (!this.selectedPlan) return true
    return false
  }
  get billingInterval() {
    if (!this.billedPlan) return ''
    return ` ${this.billedPlan.interval}`
  }
  get planPrice() {
    if (!this.billedPlan) return 0
    return this.billedPlan.plan_price
  }
  get smsPrice() {
    if (!this.billedPlan) return 0
    return this.billedPlan.sms_price
  }
  get totalPrice() {
    if (!this.billedPlan) return 0
    return this.billedPlan.total_price
  }
  get totalPlusTaxes() {
    return Math.round(this.totalPrice * 1.22)
  }

  // Methods
  async subscribe() {
    if (!this.selectedPlan || !this.billedPlan) return
    await openCheckoutSession(this.billedPlan.id)
  }

  // Watchers
  @Watch('optionSmsChecked')
  onSmsCheckboxChange() {
    if (this.selectedPlan) this.selected = this.selectedPlan
  }
}
