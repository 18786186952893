import dayjs from 'dayjs'
import { BaseUtils } from './base'

export interface IExamBase {
  ref?: string
  description?: string
  insertion_date: string
  delivery_date?: string
  medical_report?: string
  exam_type_id?: number
  patient_id: number
}

export type IExamCreate = IExamBase

export interface IExamUpdate {
  ref?: string
  description?: string
  insertion_date?: string
  delivery_date?: string
  medical_report?: string
  exam_type_id?: number
}

export interface IExam extends IExamBase {
  id: number
  type_name?: string
  patient_name: string
  num_reports: number
  created_at: string
  updated_at: string
}

class ExamUtilsImpl extends BaseUtils {
  createInit(): IExamCreate {
    return {
      ref: undefined,
      description: undefined,
      insertion_date: dayjs().format('YYYY-MM-DD'),
      delivery_date: undefined,
      medical_report: undefined,
      exam_type_id: undefined,
      patient_id: 0,
    }
  }

  updateInit(obj: IExam | null): IExamUpdate {
    if (obj)
      return {
        ref: obj.ref,
        description: obj.description,
        insertion_date: obj.insertion_date,
        delivery_date: obj.delivery_date,
        medical_report: obj.medical_report,
        exam_type_id: obj.exam_type_id,
      }
    return {}
  }
}

export const ExamUtils = new ExamUtilsImpl()
