import { BaseUtils } from './base'

export interface IExamTypeBase {
  name: string
  description?: string
  delivery_days?: number
}

export type IExamTypeCreate = IExamTypeBase

export interface IExamTypeUpdate {
  name?: string
  description?: string
  delivery_days?: number
}

export interface IExamType extends IExamTypeBase {
  id: number
}

class ExamTypeUtilsImpl extends BaseUtils {
  createInit(): IExamTypeCreate {
    return {
      name: '',
      description: undefined,
      delivery_days: undefined,
    }
  }

  updateInit(obj: IExamType | null): IExamTypeUpdate {
    if (obj)
      return {
        name: obj.name,
        description: obj.description,
        delivery_days: obj.delivery_days,
      }
    return {}
  }
}

export const ExamTypeUtils = new ExamTypeUtilsImpl()
