






























































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { ValidationObserver } from 'vee-validate'
import { showInfoNotification } from '../../utils/notification'
import TitleBar from '@/components/Eterian/TitleBar.vue'
import CardComponent from '@/components/CardComponent.vue'
import ModalBox from '@/components/Eterian/ModalBox.vue'
import ConditionalTooltipButton from '@/components/Eterian/form/ConditionalTooltipButton.vue'
import InputFieldWithValidation from '@/components/Eterian/form/InputFieldWithValidation.vue'
import SelectFieldWithValidation from '@/components/Eterian/form/SelectFieldWithValidation.vue'
import DatePickerWithValidation from '@/components/Eterian/form/DatePickerWithValidation.vue'
import { IReportCreate, ReportUtils } from '@/store/interfaces/report-model'
import { ReportStore } from '@/store/modules/report'
import { IFileInfo, UploadFileService, UploadStatus } from '@/store/api/admin/upload'
import { checkApiError, ErrorCode } from '@/store/api/error-handlers'
import axios from 'axios'
import DynamicSection from '@/components/Eterian/DynamicSection.vue'

@Component({
  components: {
    ValidationObserver,
    CardComponent,
    TitleBar,
    ModalBox,
    ConditionalTooltipButton,
    InputFieldWithValidation,
    SelectFieldWithValidation,
    DatePickerWithValidation,
    DynamicSection,
  },
})
export default class AddReport extends Vue {
  // Properties
  @Prop({ type: Number, required: true })
  id!: number // Exam ID

  // Data
  isModalActive = false
  form: IReportCreate = ReportUtils.createInit(this.id)

  files: File[] = []
  fileInfo: IFileInfo[] = []
  uploadActionCounter = 0
  uploadInputDisabled = false

  // Computed Data
  get titleStack() {
    return ['Amministrazione', 'Esami', 'Report', 'Inserisci']
  }
  get validFileCounter() {
    let counter = 0
    this.fileInfo.forEach((item) => {
      if (item.status == UploadStatus.STATUS_SUCCESS) counter++
    })
    this.uploadInputDisabled = counter >= 10 ? true : false
    return counter
  }
  get isSubmitDisabled() {
    if (this.uploadActionCounter == 0 && this.validFileCounter > 0 && this.validFileCounter <= 10) return false
    return true
  }

  // Methods
  async addFiles() {
    const diff = this.files.length - this.fileInfo.length
    if (diff == 0) return
    for (let i = 0; i < diff; i++) {
      let currentFile = this.files[this.fileInfo.length]
      let currentInfo = UploadFileService.createFileInfo()
      this.fileInfo.push(currentInfo)
      if (currentFile.type !== 'application/pdf')
        UploadFileService.setStatusFailed(currentInfo, ErrorCode.UPLOAD_INVALID_PDF, true)
      else if (currentFile.size > 10485760)
        UploadFileService.setStatusFailed(currentInfo, ErrorCode.REQUEST_TOO_LARGE, true)
      else this.uploadFile(currentFile, currentInfo)
    }
  }
  async removeFile(index: number) {
    if (this.cancelUpload(this.fileInfo[index])) {
      await new Promise((resolve) => setTimeout(resolve, 500))
    }
    this.files.splice(index, 1)
    this.fileInfo.splice(index, 1)
  }
  async uploadFile(file: File, info: IFileInfo) {
    try {
      this.uploadActionCounter++
      const response = await UploadFileService.upload(file, info)
      if (response) UploadFileService.setStatusOk(info, response.data.tmp_file_name)
      else throw new Error('Unexpected error')
    } catch (error) {
      if (axios.isCancel(error)) {
        UploadFileService.setStatusCancelled(info)
      } else {
        const errorCode = checkApiError(error, false)
        UploadFileService.setStatusFailed(info, errorCode)
      }
    } finally {
      this.uploadActionCounter--
    }
  }
  cancelUpload(info: IFileInfo) {
    if (info.status == UploadStatus.STATUS_UPLOADING) {
      info.cancelTokenSource.cancel()
      return true
    }
    return false
  }
  async onSubmit() {
    // Get tmp_file_list
    this.form.tmp_file_list = []
    this.fileInfo.forEach((item) => {
      if (item.status == UploadStatus.STATUS_SUCCESS && item.tmp_file) this.form.tmp_file_list.push(item.tmp_file)
    })
    // Check if undefined field has been modified to empty string
    ReportUtils.createCleanUndefined(this.form)
    const result = await ReportStore.actionCreate(this.form)
    if (result) {
      this.$router.back()
    }
  }
  onReset() {
    this.fileInfo.forEach((item) => {
      this.cancelUpload(item)
    })
    this.form = ReportUtils.createInit(this.id)
    this.files = []
    this.fileInfo = []
    showInfoNotification({ content: 'Campi azzerati' })
  }
  onCancel() {
    if (ReportUtils.createCheckHasChanged(this.form) || this.files.length > 0) {
      this.isModalActive = true
    } else {
      this.fileInfo.forEach((item) => {
        this.cancelUpload(item)
      })
      this.$router.back()
    }
  }
}
