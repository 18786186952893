





















































































import { Vue, Component, Prop } from 'vue-property-decorator'
import ExamTable from './tables/ExamTable.vue'
import CardComponent from '@/components/CardComponent.vue'
import ModalBox from '@/components/Eterian/ModalBox.vue'
import TitleBar, { ITitleBarButton } from '@/components/Eterian/TitleBar.vue'
import CardButton from '@/components/Eterian/CardButton.vue'
import CardToolbar from '@/components/CardToolbar.vue'
import { showInfoNotification } from '../../utils/notification'
import { ExamStore } from '@/store/modules/exam'
import dayjs from 'dayjs'
import DynamicSection from '@/components/Eterian/DynamicSection.vue'

@Component({
  components: {
    CardToolbar,
    CardButton,
    TitleBar,
    CardComponent,
    ExamTable,
    ModalBox,
    DynamicSection,
  },
})
export default class Exams extends Vue {
  // Properties
  @Prop({ type: String, default: 'all' })
  defaultShow!: string

  // Data
  isLoading = false
  show = this.defaultShow
  searchString = ''
  filterString = ''
  isModalActive = false
  trashObject: Record<string, any> | null = null

  loadMoreExamEnabled = true
  loadMoreExamNotReportedEnabled = true
  examSkip = 0
  notReportedExamSkip = 0
  dataLimit = 15

  // Computed Data
  get titleStack() {
    return ['Amministrazione', 'Esami']
  }
  get titleBarButton() {
    return {
      label: 'Aggiungi un Esame',
      icon: 'medical-bag',
      to: '/main/exams/add',
    } as ITitleBarButton
  }
  get examTableData() {
    if (this.filterString) return ExamStore.filteredData
    switch (this.show) {
      case 'all':
        return ExamStore.data
      case 'not_reported':
        return ExamStore.notReportedExams
      default:
        return []
    }
  }
  get showLoadMoreButton() {
    if (this.filterString) return false
    switch (this.show) {
      case 'all':
        return this.loadMoreExamEnabled
      case 'not_reported':
        return this.loadMoreExamNotReportedEnabled
      default:
        return false
    }
  }
  get trashObjectName() {
    if (this.trashObject) {
      const examDate = dayjs(String(this.trashObject.insertion_date)).format('DD/MM/YYYY')
      const typeName = this.trashObject.type_name ? this.trashObject.type_name : 'Generico'
      return `${typeName} (${this.trashObject.patient_name}) del ${examDate}`
    }
    return null
  }

  // Vue Hooks
  async mounted() {
    this.loadExams()
    await this.loadNotReportedExams()
  }

  // Methods
  async showAll() {
    if (this.show == 'all') return
    this.show = 'all'
  }
  async showNotReported() {
    if (this.show == 'not_reported') return
    this.show = 'not_reported'
  }
  async loadExams() {
    this.isLoading = true
    const result = await ExamStore.actionGetMulti({ skip: this.examSkip, limit: this.dataLimit })
    if (result != null) {
      if (result < this.dataLimit) {
        this.loadMoreExamEnabled = false
      }
      this.examSkip += result
    }
    this.isLoading = false
  }
  async loadNotReportedExams() {
    this.isLoading = true
    const result = await ExamStore.actionGetMultiNotReported({ skip: this.notReportedExamSkip, limit: this.dataLimit })
    if (result != null) {
      if (result < this.dataLimit) {
        this.loadMoreExamNotReportedEnabled = false
      }
      this.notReportedExamSkip += result
    }
    this.isLoading = false
  }
  async searchExams() {
    if (this.searchString) {
      this.isLoading = true
      const result = await ExamStore.actionSearch(this.searchString)
      if (result) {
        this.filterString = this.searchString
        this.searchString = ''
        document.getElementById('searchField')?.blur()
      }
      this.isLoading = false
    }
  }
  async refreshData(notify = true) {
    this.isLoading = true
    let result
    if (this.filterString) {
      result = await ExamStore.actionSearch(this.filterString)
    } else if (this.show == 'all') {
      result = await this.refreshExams()
    } else if (this.show == 'not_reported') {
      result = await this.refreshNotReportedExams()
    }
    if (result && notify) {
      showInfoNotification({ content: 'Aggiornato' })
    }
    this.isLoading = false
  }
  async refreshExams() {
    let computedDataSkip = this.examSkip
    if (!this.loadMoreExamEnabled) {
      computedDataSkip += this.dataLimit
    }
    return ExamStore.actionGetMulti({ limit: computedDataSkip })
  }
  async refreshNotReportedExams() {
    let computedDataSkip = this.notReportedExamSkip
    if (!this.loadMoreExamNotReportedEnabled) {
      computedDataSkip += this.dataLimit
    }
    return ExamStore.actionGetMultiNotReported({ limit: computedDataSkip })
  }
  resetSearchForm() {
    if (this.filterString) {
      this.isLoading = true
      this.searchString = ''
      this.filterString = ''
      this.isLoading = false
    }
  }

  trashModal(trashObject: Record<string, any>) {
    this.trashObject = trashObject
    this.isModalActive = true
  }
  async trashConfirm() {
    this.isModalActive = false
    await this.deleteExam()
  }
  async deleteExam() {
    if (this.trashObject) {
      const result = await ExamStore.actionDelete(this.trashObject.id)
      if (result) {
        await this.refreshData(false)
      }
    }
  }
}
