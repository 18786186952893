



































































import { Vue, Component } from 'vue-property-decorator'
import TitleBar from '@/components/Eterian/TitleBar.vue'
import CardComponent from '@/components/CardComponent.vue'
import ModalBox from '@/components/Eterian/ModalBox.vue'
import DynamicSection from '@/components/Eterian/DynamicSection.vue'
import { SettingsStore } from '@/store/modules/settings'
import { ISettingsUpdate, SettingsUtils } from '@/store/interfaces/settings-model'
import { ValidationObserver } from 'vee-validate'
import InputFieldWithValidation from '@/components/Eterian/form/InputFieldWithValidation.vue'

@Component({
  components: {
    CardComponent,
    TitleBar,
    ModalBox,
    DynamicSection,
    ValidationObserver,
    InputFieldWithValidation,
  },
})
export default class UpdateSettings extends Vue {
  $refs!: {
    form: InstanceType<typeof ValidationObserver>
  }

  // Data
  isModalActive = false
  wantsNewsletterText = this.form.wants_newsletter
    ? 'La sottoscrizione è abilitata.'
    : 'La sottoscrizione è disabilitata. Clicca sullo switch a fianco per attivarla.'

  // Computed Data
  get titleStack() {
    return ['Amministrazione', 'Impostazioni', 'Modifica']
  }
  get settings() {
    return SettingsStore.data
  }
  get form(): ISettingsUpdate {
    return SettingsUtils.updateInit(this.settings)
  }

  // Vue Hooks
  async mounted() {
    await this.loadSettings()
  }

  // Methods
  async loadSettings() {
    if (this.settings) return
    await SettingsStore.actionGet()
  }
  async onSubmit() {
    if (!this.settings) return
    const diffObj = SettingsUtils.updateGetDiff(this.form, this.settings)
    const result = await SettingsStore.actionUpdate(diffObj)
    if (result) {
      this.$router.back()
    }
  }
  onCancel() {
    if (!this.settings) return
    if (SettingsUtils.updateCheckHasChanged(this.form, this.settings)) {
      this.isModalActive = true
    } else {
      this.$router.back()
    }
  }
  onWantsNewsletterChange(value: boolean) {
    this.wantsNewsletterText = value
      ? 'La sottoscrizione è abilitata.'
      : 'La sottoscrizione è disabilitata. Clicca sullo switch a fianco per attivarla.'
  }
}
