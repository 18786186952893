











import { Vue, Component, Prop } from 'vue-property-decorator'
import WrappedComponent from 'vue-wrapped-component'

@Component({
  components: {
    WrappedComponent,
  },
})
export default class ConditionalTooltipButton extends Vue {
  // Properties
  @Prop({ type: String, required: true })
  btnText!: string
  @Prop({ type: String, default: 'is-primary' })
  btnType!: string
  @Prop(String)
  btnNativeType: string | undefined
  @Prop({ type: String, default: 'Disabilitato' })
  tpText!: string
  @Prop({ type: String, default: 'is-dark' })
  tpType!: string
  @Prop({ type: String, default: 'is-top' })
  tpPosition!: string
  @Prop({ type: Boolean, required: true })
  condition!: boolean
}
