









































































import { Vue, Component, Prop } from 'vue-property-decorator'
import CardComponent from '@/components/CardComponent.vue'
import CardButton from '@/components/Eterian/CardButton.vue'
import ModalBox from '@/components/Eterian/ModalBox.vue'
import TitleBar from '@/components/Eterian/TitleBar.vue'
import CardToolbar from '@/components/CardToolbar.vue'
import Tiles from '@/components/Eterian/Tiles.vue'
import ReportTable from './tables/ReportTable.vue'
import { ReportStore } from '@/store/modules/report'
import { ExamStore } from '@/store/modules/exam'
import { RoutePaths } from '@/router/route-details'
import DynamicSection from '@/components/Eterian/DynamicSection.vue'

@Component({
  components: {
    CardToolbar,
    CardButton,
    TitleBar,
    Tiles,
    CardComponent,
    ModalBox,
    ReportTable,
    DynamicSection,
  },
})
export default class Exam extends Vue {
  // Properties
  @Prop({ type: Number, required: true })
  id!: number

  // Data
  isLoading = false
  isModalActive = false
  trashObject: Record<string, any> | null = null

  // Computed Data
  get titleStack() {
    return ['Amministrazione', 'Esami', 'Visualizza']
  }
  get exam() {
    return ExamStore.current
  }
  get reports() {
    return ReportStore.data
  }
  get trashObjectName() {
    if (this.trashObject) {
      return this.trashObject.name
    }
    return null
  }

  // Vue Hooks
  async mounted() {
    await this.loadExam()
    await this.loadReports()
  }

  // Methods
  async loadExam() {
    if (this.exam && this.exam.id == this.id) return
    await ExamStore.actionGet(this.id)
  }
  async loadReports() {
    this.isLoading = true
    await ReportStore.actionGetAllByExamId(this.id)
    this.isLoading = false
  }
  trashModal(trashObject: Record<string, any>) {
    this.trashObject = trashObject
    this.isModalActive = true
  }
  async trashConfirm() {
    this.isModalActive = false
    await this.deleteReport()
  }
  onAddReportClick() {
    this.$router.push(RoutePaths.addReport(this.id))
  }
  async deleteReport() {
    if (this.trashObject) {
      const result = await ReportStore.actionDelete(this.trashObject.id)
      if (result) {
        await this.loadReports()
      }
    }
  }
  async downloadReport(id: number) {
    await ReportStore.actionDownloadReport(id)
  }
  routeToUpdateExam() {
    if (!this.exam) return
    this.$router.push(RoutePaths.updateExam(this.exam.id))
  }
}
