import dayjs from 'dayjs'

const HOLIDAYS = [
  '0 1', // Capodanno
  '0 6', // Epifania
  '3 25', // Liberazione
  '4 1', // Festa del Lavoro
  '5 2', // Festa della Repubblica
  '7 15', // Ferragosto
  '10 1', // Ognissanti
  '11 8', // Immacolata
  '11 25', // Natale
  '11 26', // Santo Stefano
]
const PASQUETTE: Record<number, string> = {
  2021: '3 5',
  2022: '3 18',
  2023: '3 10',
  2024: '3 1',
  2025: '3 21',
  2026: '3 6',
  2027: '2 29',
  2028: '3 17',
  2029: '3 2',
  2030: '3 22',
  2031: '3 14',
}

function addDayWithHolidayCheck(date: dayjs.Dayjs): dayjs.Dayjs {
  const newDate = date.add(1, 'day'),
    day = newDate.day(),
    year = newDate.year(),
    checkDate = `${newDate.month()} ${newDate.date()}`

  const conditions = [
    day == 0 || day == 6, // Check for business days
    HOLIDAYS.includes(checkDate), // Check for holidays
    year >= 2021 && year <= 2031 && PASQUETTE[year] == checkDate, // Check for little easter
  ]

  if (conditions.includes(true)) return addDayWithHolidayCheck(newDate)
  return newDate
}

export function addDaysToDateString(
  date: string,
  numDays: number,
  dateFormat = 'YYYY-MM-DD',
  skipHolidays = true
): string {
  if (skipHolidays) {
    let newDate = dayjs(date, dateFormat)
    for (let i = 0; i < numDays; i++) {
      newDate = addDayWithHolidayCheck(newDate)
    }
    return newDate.format(dateFormat)
  }

  return dayjs(date, dateFormat).add(numDays, 'day').format(dateFormat)
}
