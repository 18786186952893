























































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { IPatient } from '@/store/interfaces/patient-model'
import { PatientStore } from '@/store/modules/patient'
import { RoutePaths } from '@/router/route-details'

@Component
export default class PatientTable extends Vue {
  // Properties
  @Prop({ type: Array, required: true })
  dataIn!: Array<Record<string, any>>
  @Prop({ type: Boolean, default: false })
  checkable!: boolean
  @Prop({ type: Boolean, default: false })
  isLoading!: boolean

  // Data
  defaultSort = 'created_at'
  stickyHeader = false
  paginated = false
  perPage = 20
  checkedRows = []

  // Methods
  onPasswordButtonClick(id: number, fullName: string, isActive: boolean) {
    this.$emit('password-clicked', id, fullName, isActive)
  }
  onPrivacyButtonClick(id: number) {
    this.$emit('privacy-clicked', id)
  }
  onEditButtonClick(row: Record<string, any>) {
    PatientStore.SET_CURRENT(row as IPatient)
    this.$router.push(RoutePaths.updatePatient(row.id))
  }
  onDeleteButtonClick(row: Record<string, any>) {
    this.$emit('delete-clicked', row)
  }
  onRowClick(row: IPatient) {
    PatientStore.SET_CURRENT(row)
    this.$router.push(RoutePaths.patient(row.id))
  }
}
