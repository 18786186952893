









































































import { RoutePaths } from '@/router/route-details'
import { IExam } from '@/store/interfaces/exam-model'
import { ExamStore } from '@/store/modules/exam'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class ExamTable extends Vue {
  // Properties
  @Prop({ type: Array, required: true })
  dataIn!: Array<Record<string, any>>
  @Prop({ type: Boolean, default: false })
  checkable!: boolean
  @Prop({ type: Boolean, default: false })
  isLoading!: boolean
  @Prop({ type: Boolean, default: false })
  hidePatientName!: boolean
  @Prop({ type: Boolean, default: false })
  hideButtons!: boolean
  @Prop({ type: Boolean, default: false })
  hideDescription!: boolean
  @Prop({ type: Boolean, default: false })
  hideDeliveryDate!: boolean
  @Prop({ type: Boolean, default: false })
  hideStatus!: boolean
  @Prop({ type: String, default: 'insertion_date' })
  defaultSort!: string
  @Prop({ type: String, default: 'desc' })
  defaultSortDirection!: string

  // Data
  stickyHeader = false
  paginated = false
  perPage = 20
  checkedRows = []

  // Methods
  onEditButtonClick(row: Record<string, any>) {
    ExamStore.SET_CURRENT(row as IExam)
    this.$router.push(RoutePaths.updateExam(row.id))
  }
  onDeleteButtonClick(event: Event, row: Record<string, any>) {
    this.$emit('delete-clicked', row)
  }
  onRowClick(row: IExam) {
    ExamStore.SET_CURRENT(row)
    this.$router.push(RoutePaths.exam(row.id))
  }
}
