




































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { showInfoNotification } from '@/utils/notification'
import ConditionalTooltipButton from '@/components/Eterian/form/ConditionalTooltipButton.vue'
import ModalBox from '@/components/Eterian/ModalBox.vue'
import { PatientStore } from '@/store/modules/patient'

@Component({
  components: {
    ModalBox,
    ConditionalTooltipButton,
  },
})
export default class PatientModalTable extends Vue {
  $refs!: {
    hasFocus: HTMLFormElement
  }

  // Properties
  @Prop({ type: Number, required: true })
  patientId!: number
  @Prop({ type: Boolean, default: true })
  hasBottomMargin!: boolean

  // Data
  isModalTableActive = false
  patientSearchString = ''
  isPatientSelected = false

  // Computed Data
  get filteredPatients() {
    return PatientStore.filteredData
  }
  get isSearchButtonDisabled() {
    return this.patientSearchString.length < 3
  }

  // Vue Hooks
  mounted() {
    this.$refs.hasFocus.focus()
  }

  // Methods
  onSelectButtonClick(row: Record<string, any>) {
    this.$emit('update:patientId', row.id)
    this.patientSearchString = `${row.surname} ${row.name}`
    this.isPatientSelected = true
    this.isModalTableActive = false
  }
  async searchPatient() {
    if (this.patientSearchString.length < 3) return
    await PatientStore.actionSearch(this.patientSearchString)
    if (this.filteredPatients.length > 0) {
      this.isModalTableActive = true
    } else {
      showInfoNotification({ content: `Nessun paziente trovato con "${this.patientSearchString}"` })
      this.patientSearchString = ''
      this.$refs.hasFocus.focus()
    }
  }
  onInputSubmit() {
    this.searchPatient()
  }
  resetSelection() {
    this.$emit('update:patientId', 0)
    this.patientSearchString = ''
    this.isPatientSelected = false
    this.$refs.hasFocus.focus()
  }
}
