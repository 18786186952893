









































import { Vue, Component } from 'vue-property-decorator'
import { ValidationObserver } from 'vee-validate'
import InputFieldWithValidation from '@/components/Eterian/form/InputFieldWithValidation.vue'
import SelectFieldWithValidation from '@/components/Eterian/form/SelectFieldWithValidation.vue'
import DatePickerWithValidation from '@/components/Eterian/form/DatePickerWithValidation.vue'
import { IUserPasswordUpdate } from '@/store/interfaces/user-model'
import { UserStore } from '@/store/modules/user'

@Component({
  components: {
    ValidationObserver,
    InputFieldWithValidation,
    SelectFieldWithValidation,
    DatePickerWithValidation,
  },
})
export default class PasswordUpdateForm extends Vue {
  $refs!: {
    form: InstanceType<typeof ValidationObserver>
  }

  // Data
  form: IUserPasswordUpdate = {
    current_password: '',
    new_password: '',
  }
  confirmPassword = ''

  // Methods
  async onSubmit() {
    const result = await UserStore.actionUpdatePassword(this.form)
    if (result) {
      this.form.current_password = ''
      this.form.new_password = ''
      this.confirmPassword = ''
      this.$nextTick(() => {
        this.$refs.form.reset()
      })
    }
  }
}
