import axios, { CancelTokenSource } from 'axios'
import { apiUrl } from '@/env'
import { uploadConfig } from '@/store/api/configs'
import { IUpload } from '@/store/interfaces/upload-model'
import { ErrorCode } from '../error-handlers'
import { UserStore } from '@/store/modules/user'

export const uploadApi = {
  async uploadReport(
    token: string,
    data: FormData,
    onUploadProgress: (event: any) => void,
    cancelTokenSource: CancelTokenSource
  ) {
    return axios.post<IUpload>(
      `${apiUrl}/v1/admin/upload/report`,
      data,
      uploadConfig(token, onUploadProgress, cancelTokenSource.token)
    )
  },
}

export enum UploadStatus {
  STATUS_UPLOADING = 0,
  STATUS_SUCCESS = 1,
  STATUS_FAILED = 2,
  STATUS_CANCELLED = 3,
}

export enum UploadColor {
  COLOR_UPLOADING = 'is-info',
  COLOR_SUCCESS = 'is-success',
  COLOR_FAILED = 'is-danger',
  COLOR_CANCELLED = 'is-warning',
}

export enum UploadMessage {
  MSG_OK = 'OK',
  MSG_CANCELLED = 'Annullato',
  MSG_INVALID_PDF = 'PDF non valido',
  MSG_SERVER_ERROR = 'Errore del server',
  MSG_FILE_TOO_LARGE = 'Troppo grande (Max 10MB)',
}

export interface IFileInfo {
  status: UploadStatus
  color: UploadColor
  progress: number
  message?: UploadMessage
  cancelTokenSource: CancelTokenSource
  tmp_file?: string
}

export class UploadFileService {
  static upload(file: File, info: IFileInfo) {
    const onUploadProgress = (event: any) => {
      info.progress = Math.round((100 * event.loaded) / event.total)
    }
    const formData = new FormData()
    formData.append('uploaded_file', file)
    return uploadApi.uploadReport(UserStore.token, formData, onUploadProgress, info.cancelTokenSource)
  }
  static createFileInfo(): IFileInfo {
    return {
      status: UploadStatus.STATUS_UPLOADING,
      color: UploadColor.COLOR_UPLOADING,
      progress: 0,
      cancelTokenSource: axios.CancelToken.source(),
    }
  }
  static setStatusOk(info: IFileInfo, tmp_file: string) {
    info.status = UploadStatus.STATUS_SUCCESS
    info.color = UploadColor.COLOR_SUCCESS
    info.message = UploadMessage.MSG_OK
    info.tmp_file = tmp_file
  }
  static setStatusCancelled(info: IFileInfo) {
    info.status = UploadStatus.STATUS_CANCELLED
    info.color = UploadColor.COLOR_CANCELLED
    info.message = UploadMessage.MSG_CANCELLED
  }
  static setStatusFailed(info: IFileInfo, errorCode: ErrorCode, fillProgress = false) {
    info.status = UploadStatus.STATUS_FAILED
    info.color = UploadColor.COLOR_FAILED
    switch (errorCode) {
      case ErrorCode.UPLOAD_INVALID_PDF:
        info.message = UploadMessage.MSG_INVALID_PDF
        break
      case ErrorCode.UPLOAD_SAVING_ERROR:
        info.message = UploadMessage.MSG_INVALID_PDF
        break
      case ErrorCode.REQUEST_TOO_LARGE:
        info.message = UploadMessage.MSG_FILE_TOO_LARGE
        break
      default:
        break
    }
    if (fillProgress) info.progress = 100
  }
}
