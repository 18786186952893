








































import { Vue, Component, Prop, PropSync } from 'vue-property-decorator'
import ModalBoxForm from '@/components/Eterian/ModalBoxForm.vue'
import InputFieldWithValidation from '@/components/Eterian/form/InputFieldWithValidation.vue'
import { ExamTypeStore } from '@/store/modules/exam-type'
import { ExamTypeUtils, IExamTypeUpdate } from '@/store/interfaces/exam-type-model'

@Component({
  components: {
    ModalBoxForm,
    InputFieldWithValidation,
  },
})
export default class UpdateExamType extends Vue {
  // Properties
  @PropSync('isActive', { type: Boolean, required: true })
  isModalActive!: boolean
  @Prop({ type: Number, required: true })
  id!: number

  // Data
  form: IExamTypeUpdate = {}

  // Computed Data
  get examType() {
    return ExamTypeStore.current
  }

  // Methods
  loadExamType() {
    this.form = ExamTypeUtils.updateInit(this.examType)
  }
  modalCancel() {
    this.isModalActive = false
    this.form = {}
    this.$emit('op-aborted')
  }
  async modalConfirm() {
    if (!this.form || !this.examType) return
    const diffObj = ExamTypeUtils.updateGetDiff(this.form, this.examType)
    const result = await ExamTypeStore.actionUpdate({ id: this.id, obj: diffObj })
    if (result) this.$emit('op-confirmed')
    this.isModalActive = false
  }
}
