

























































import { IProductPlan } from '@/store/interfaces/billing-model'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class PaymentTable extends Vue {
  // Properties
  @Prop({ type: Array, required: true })
  dataIn!: Array<Record<string, any>>
  @Prop({ type: Object, required: true })
  productPlanDict!: Record<string, IProductPlan>
  @Prop({ type: Boolean, default: false })
  checkable!: boolean
  @Prop({ type: Boolean, default: false })
  isLoading!: boolean

  // Data
  defaultSort = 'created_at'
  stickyHeader = false
  paginated = false
  perPage = 10
  checkedRows = []

  // Method
  getBillingPlanName(plan_id: string): string {
    const plan = this.productPlanDict[plan_id]
    if (plan) return `${plan.name} (${plan.interval})`
    return ''
  }
}
