import { BaseUtils } from './base'

export interface IPatientBase {
  name: string
  surname: string
  gender: string
  address?: string
  zip_code?: string
  city?: string
  province?: string
  country?: string
  birth_date: string
  birth_place: string
  birth_province: string
  email?: string
  mobile_number?: string
  fiscal_code: string
}

export type IPatientCreate = IPatientBase

export interface IPatientUpdate {
  name?: string
  surname?: string
  gender?: string
  address?: string
  zip_code?: string
  city?: string
  province?: string
  country?: string
  birth_date?: string
  birth_place?: string
  birth_province?: string
  email?: string
  mobile_number?: string
  fiscal_code?: string
}

export interface IPatient extends IPatientBase {
  id: number
  username: string
  must_change_password: boolean
  is_active: boolean
  created_at: string
  updated_at: string
}

class PatientUtilsImpl extends BaseUtils {
  createInit(): IPatientCreate {
    return {
      name: '',
      surname: '',
      gender: '',
      birth_date: '',
      birth_place: '',
      birth_province: '',
      fiscal_code: '',
      address: undefined,
      zip_code: undefined,
      city: undefined,
      province: undefined,
      country: 'Italia',
      email: undefined,
      mobile_number: undefined,
    }
  }

  updateInit(obj: IPatient | null): IPatientUpdate {
    if (obj)
      return {
        name: obj.name,
        surname: obj.surname,
        gender: obj.gender,
        address: obj.address,
        zip_code: obj.zip_code,
        city: obj.city,
        province: obj.province,
        country: obj.country,
        birth_date: obj.birth_date,
        birth_place: obj.birth_place,
        birth_province: obj.birth_province,
        email: obj.email,
        mobile_number: obj.mobile_number,
        fiscal_code: obj.fiscal_code,
      }
    return {}
  }
}

export const PatientUtils = new PatientUtilsImpl()
