


























































import { RoutePaths } from '@/router/route-details'
import { IUserProfile } from '@/store/interfaces/user-model'
import { OperatorStore } from '@/store/modules/operator'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class OperatorTable extends Vue {
  // Properties
  @Prop({ type: Array, required: true })
  dataIn!: Array<Record<string, any>>
  @Prop({ type: Boolean, default: false })
  checkable!: boolean
  @Prop({ type: Boolean, default: false })
  isLoading!: boolean

  // Data
  defaultSort = 'created_at'
  stickyHeader = false
  paginated = false
  perPage = 20
  checkedRows = []

  // Methods
  onEditButtonClick(row: Record<string, any>) {
    OperatorStore.SET_CURRENT(row as IUserProfile)
    this.$router.push(RoutePaths.updateOperator(row.id))
  }
  onDeleteButtonClick(row: Record<string, any>) {
    this.$emit('delete-clicked', row)
  }
}
