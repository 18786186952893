
















































































import { Vue, Component, Prop } from 'vue-property-decorator'
import ExamTable from './tables/ExamTable.vue'
import CardComponent from '@/components/CardComponent.vue'
import CardButton from '@/components/Eterian/CardButton.vue'
import ModalBox from '@/components/Eterian/ModalBox.vue'
import TitleBar from '@/components/Eterian/TitleBar.vue'
import { showInfoNotification } from '../../utils/notification'
import { ExamStore } from '@/store/modules/exam'
import dayjs from 'dayjs'
import { PatientStore } from '@/store/modules/patient'
import { RouteNames, RoutePaths } from '@/router/route-details'
import DynamicSection from '@/components/Eterian/DynamicSection.vue'

@Component({
  components: {
    TitleBar,
    CardComponent,
    CardButton,
    ExamTable,
    ModalBox,
    DynamicSection,
  },
})
export default class Patient extends Vue {
  // Properties
  @Prop({ type: Number, required: true })
  id!: number

  // Data
  isLoading = false
  isModalActive = false
  trashObject: Record<string, any> | null = null

  loadMoreEnabled = true
  dataSkip = 0
  dataLimit = 10

  // Computed Data
  get titleStack() {
    return ['Amministrazione', 'Pazienti', 'Visualizza']
  }
  get patient() {
    return PatientStore.current
  }
  get exams() {
    return ExamStore.data
  }
  get showLoadMoreButton() {
    return this.loadMoreEnabled
  }
  get trashObjectName() {
    if (this.trashObject) {
      const examDate = dayjs(String(this.trashObject.insertion_date)).format('DD/MM/YYYY')
      const typeName = this.trashObject.type_name ? this.trashObject.type_name : 'Generico'
      return `${typeName} (${this.trashObject.patient_name}) del ${examDate}`
    }
    return null
  }

  // Vue Hooks
  async mounted() {
    await this.loadPatient()
    await this.loadExams()
  }

  // Methods
  async loadPatient() {
    if (this.patient && this.patient.id == this.id) return
    await PatientStore.actionGet(this.id)
  }
  async loadExams() {
    this.isLoading = true
    const result = await ExamStore.actionGetMultiByPatientId({
      patientId: this.id,
      skip: this.dataSkip,
      limit: this.dataLimit,
    })
    if (result != null) {
      if (result < this.dataLimit) {
        this.loadMoreEnabled = false
      }
      this.dataSkip += result
    }
    this.isLoading = false
  }
  async refreshExams(notify = true) {
    this.isLoading = true
    let computedDataSkip = this.dataSkip
    if (!this.loadMoreEnabled) {
      computedDataSkip += this.dataLimit
    }
    const result = await ExamStore.actionGetMultiByPatientId({ patientId: this.id, limit: computedDataSkip })
    if (result && notify) {
      showInfoNotification({ content: 'Aggiornato' })
    }
    this.isLoading = false
  }

  trashModal(trashObject: Record<string, any>) {
    this.trashObject = trashObject
    this.isModalActive = true
  }
  async trashConfirm() {
    this.isModalActive = false
    await this.deleteExam()
  }
  async deleteExam() {
    if (this.trashObject) {
      const result = await ExamStore.actionDelete(this.trashObject.id)
      if (result) {
        await this.refreshExams(false)
      }
    }
  }
  async resetPatientPassword() {
    if (!this.patient) return
    const fullName = `${this.patient.surname} ${this.patient.name}`
    this.$buefy.dialog.confirm({
      title: 'Reset Password Paziente',
      message: `Sei sicuro di voler <b>resettare</b> la password del paziente <b>${fullName}</b>?<br>Ricorda di comunicare al paziente la nuova password generata.`,
      confirmText: 'Resetta la Password',
      cancelText: 'Annulla',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: async () => {
        if (this.patient)
          await PatientStore.actionResetPatientPassword({ id: this.patient.id, mustChangePassword: false })
      },
    })
  }
  routeToAddExam() {
    if (!this.patient) return
    const patientId = this.patient.id.toString()
    const patientFullname = `${this.patient.surname} ${this.patient.name}`
    this.$router.push({
      name: RouteNames.addExam,
      params: { injectedPatientId: patientId, injectedPatientFullname: patientFullname },
    })
  }
  routeToUpdatePatient() {
    if (!this.patient) return
    this.$router.push(RoutePaths.updatePatient(this.patient.id))
  }
}
