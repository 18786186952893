
import { Vue, Component, Prop } from 'vue-property-decorator'
import chunk from 'lodash/chunk'

@Component
export default class Tiles extends Vue {
  // Properties
  @Prop({ type: Number, default: 5 })
  maxPerRow!: number

  // Data
  windowOuterWidth = 0

  // Computed Data
  get newMaxPerRow() {
    return this.windowOuterWidth >= 769 && this.windowOuterWidth <= 1024 ? 2 : this.maxPerRow
  }

  // Vue Hooks
  mounted() {
    this.updateWindowOuterWidth()
    window.addEventListener('resize', this.updateWindowOuterWidth)
  }
  beforeDestroy() {
    window.removeEventListener('resize', this.updateWindowOuterWidth)
  }
  render(createElement: any) {
    if (this.$slots.default && this.$slots.default.length <= this.newMaxPerRow) {
      return this.renderAncestor(createElement, this.$slots.default)
    } else {
      return createElement(
        'div',
        { attrs: { class: 'is-tiles-wrapper' } },
        chunk(this.$slots.default, this.newMaxPerRow).map((group) => {
          return this.renderAncestor(createElement, group)
        })
      )
    }
  }

  // Methods
  updateWindowOuterWidth() {
    this.windowOuterWidth = window.outerWidth
  }
  renderAncestor(createElement: any, elements: any) {
    return createElement(
      'div',
      { attrs: { class: 'tile is-ancestor' } },
      elements.map((element: any) => {
        return createElement('div', { attrs: { class: 'tile is-parent' } }, [element])
      })
    )
  }
}
