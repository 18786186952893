



























































import { Vue, Component, Prop, PropSync, Watch } from 'vue-property-decorator'
import { ValidationObserver } from 'vee-validate'
import { showInfoNotification } from '@/utils/notification'
import ConditionalTooltipButton from '@/components/Eterian/form/ConditionalTooltipButton.vue'
import InputFieldWithValidation from '@/components/Eterian/form/InputFieldWithValidation.vue'
import SelectFieldWithValidation, { ISelectOption } from '@/components/Eterian/form/SelectFieldWithValidation.vue'
import DatePickerWithValidation from '@/components/Eterian/form/DatePickerWithValidation.vue'
import { ExamTypeStore } from '@/store/modules/exam-type'
import { addDaysToDateString } from '@/utils/dates'

@Component({
  components: {
    ValidationObserver,
    InputFieldWithValidation,
    SelectFieldWithValidation,
    DatePickerWithValidation,
    ConditionalTooltipButton,
  },
})
export default class ExamForm extends Vue {
  $refs!: {
    form: InstanceType<typeof ValidationObserver>
  }

  // Properties
  @Prop({ type: Boolean, default: false })
  isUpdateForm!: boolean
  @PropSync('formData', { type: Object, required: true })
  form!: Record<string, any>

  // Computed Data
  get submitBtnText() {
    if (this.isUpdateForm) return 'Modifica'
    else return 'Inserisci'
  }
  get examTypes() {
    let examTypes: ISelectOption[] = []
    for (const examType of ExamTypeStore.data) {
      examTypes.push({ value: examType.id, tag: examType.name })
    }
    // Order examTypes
    // TODO: remove ordering when exam type will be inserted obligatorily with first uppercase letter
    examTypes.sort((a, b) => {
      const al = a.tag.toLowerCase()
      const bl = b.tag.toLowerCase()
      if (bl < al) return 1
      else if (bl > al) return -1
      else return 0
    })
    return examTypes
  }
  get isSubmitDisabled() {
    return this.form.patient_id == 0
  }

  // Vue Hooks
  mounted() {
    this.getExamTypes()
  }

  // Methods
  async getExamTypes() {
    await ExamTypeStore.actionGetAll()
  }
  onSubmit() {
    this.$emit('submit')
  }
  onReset() {
    // Reset form validator on next tick because changes are still propagating
    this.$nextTick(() => {
      this.$refs.form.reset()
    })
    showInfoNotification({ content: 'Campi azzerati' })
    this.$emit('reset')
  }
  onCancel() {
    this.$emit('cancel')
  }

  // Watchers
  @Watch('form.exam_type_id')
  onExamTypeIdChange(newValue: number) {
    if (newValue && this.form.insertion_date) {
      const examType = ExamTypeStore.data.find((element) => element.id == newValue)
      if (examType && examType.delivery_days) {
        this.form.delivery_date = addDaysToDateString(this.form.insertion_date, examType.delivery_days)
        return
      }
    }
    this.form.delivery_date = ''
  }

  @Watch('form.insertion_date')
  onInsertionDateChange(newValue: string) {
    if (newValue) {
      const examType = ExamTypeStore.data.find((element) => element.id == this.form.exam_type_id)
      if (examType && examType.delivery_days) {
        this.form.delivery_date = addDaysToDateString(newValue, examType.delivery_days)
        return
      }
    }
    this.form.delivery_date = ''
  }
}
