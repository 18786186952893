import { NotImplementedError } from '../api/error-handlers'
import { BaseUtilsWithSudoUpdate } from './base'

export interface ISettingsBase {
  denomination: string
  short_denomination: string
  web_site?: string
  dpo_full_name?: string
  gdpr_full_name?: string
  privacy_number?: string
  wants_newsletter: boolean
  plan_id?: string
  subscription_status?: string
  subscription_start_date?: string
  subscription_current_period_start?: string
  subscription_current_period_end?: string
  subscription_cancel_at_period_end?: boolean
  has_sms_option?: boolean
}

export interface ISettingsCreate {
  nothing: undefined
}

export interface ISettingsUpdate {
  denomination?: string
  short_denomination?: string
  web_site?: string
  dpo_full_name?: string
  gdpr_full_name?: string
  privacy_number?: string
  wants_newsletter?: boolean
}

export interface ISudoSettingsUpdate extends ISettingsUpdate {
  customer_id?: string
  subscription_id?: string
  plan_id?: string
  subscription_status?: string
  subscription_start_date?: string
  subscription_current_period_start?: string
  subscription_current_period_end?: string
  subscription_cancel_at_period_end?: boolean
  has_sms_option?: boolean
}

export interface ISettings extends ISettingsBase {
  id: number
}

class SettingsUtilsImpl extends BaseUtilsWithSudoUpdate {
  createInit(): ISettingsCreate {
    throw NotImplementedError
  }

  updateInit(obj: ISettings | null): ISettingsUpdate {
    if (obj)
      return {
        denomination: obj.denomination,
        short_denomination: obj.short_denomination,
        web_site: obj.web_site,
        dpo_full_name: obj.dpo_full_name,
        gdpr_full_name: obj.gdpr_full_name,
        privacy_number: obj.privacy_number,
        wants_newsletter: obj.wants_newsletter,
      }
    return {}
  }

  sudoUpdateInit(obj: ISettings | null): ISudoSettingsUpdate {
    if (obj)
      return {
        ...this.updateInit(obj),
        customer_id: '',
        subscription_id: '',
        plan_id: obj.plan_id,
        subscription_status: obj.subscription_status,
        subscription_start_date: obj.subscription_start_date,
        subscription_current_period_start: obj.subscription_current_period_start,
        subscription_current_period_end: obj.subscription_current_period_end,
        subscription_cancel_at_period_end: obj.subscription_cancel_at_period_end,
        has_sms_option: obj.has_sms_option,
      }
    return {}
  }
}

export const SettingsUtils = new SettingsUtilsImpl()
