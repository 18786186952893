









import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class TextInfo extends Vue {
  // Properties
  @Prop({ type: String, required: true })
  label!: string
}
