
























import { Vue, Component } from 'vue-property-decorator'
import CardComponent from '@/components/CardComponent.vue'
import TitleBar from '@/components/Eterian/TitleBar.vue'
import Tiles from '@/components/Eterian/Tiles.vue'
import PasswordUpdateForm from '@/views/forms/PasswordUpdateForm.vue'
import { UserStore } from '@/store/modules/user'
import DynamicSection from '@/components/Eterian/DynamicSection.vue'

@Component({
  components: {
    DynamicSection,
    CardComponent,
    TitleBar,
    Tiles,
    PasswordUpdateForm,
  },
})
export default class Profile extends Vue {
  // Computed Data
  get titleStack() {
    return ['Generale', 'Profilo']
  }
  get isAdministrator() {
    return UserStore.hasAdminAccess
  }
  get userName() {
    return UserStore.userProfile?.full_name
  }
  get userEmail() {
    return UserStore.userProfile?.email
  }
}
