













































































































































import { Vue, Component, Prop, PropSync, Watch } from 'vue-property-decorator'
import { ValidationObserver } from 'vee-validate'
import { showInfoNotification } from '@/utils/notification'
import InputFieldWithValidation from '@/components/Eterian/form/InputFieldWithValidation.vue'
import SelectFieldWithValidation, { ISelectOption } from '@/components/Eterian/form/SelectFieldWithValidation.vue'
import DatePickerWithValidation from '@/components/Eterian/form/DatePickerWithValidation.vue'
import autocompleteApi from '@/utils/autocomplete-api'
import { Validator, Parser } from '@marketto/codice-fiscale-utils'

@Component({
  components: {
    ValidationObserver,
    InputFieldWithValidation,
    SelectFieldWithValidation,
    DatePickerWithValidation,
  },
})
export default class PatientForm extends Vue {
  $refs!: {
    form: InstanceType<typeof ValidationObserver>
    formSurname: InputFieldWithValidation
  }

  // Properties
  @Prop({ type: Boolean, default: false })
  isUpdateForm!: boolean
  @PropSync('formData', { type: Object, required: true })
  form!: Record<string, any>

  // Data
  genders: ISelectOption[] = [
    { value: 'M', tag: 'Maschio' },
    { value: 'F', tag: 'Femmina' },
    { value: 'N', tag: 'Non specificato' },
  ]
  countryArray = new Array(225)
  cityArray = new Array(10243)
  provinceArray = new Array(116)

  // Computed Data
  get submitBtnText() {
    if (this.isUpdateForm) return 'Modifica'
    else return 'Inserisci'
  }

  // Vue Hooks
  mounted() {
    this.populateCountryArray()
    this.populateCityArray()
    this.populateProvinceArray()
  }

  // Methods
  async populateCountryArray() {
    const response = await autocompleteApi.getCountries()
    if (response) {
      this.countryArray = response.data
    }
  }
  async populateCityArray() {
    const response = await autocompleteApi.getCities()
    if (response) {
      this.cityArray = response.data
    }
  }
  async populateProvinceArray() {
    const response = await autocompleteApi.getProvinces()
    if (response) {
      this.provinceArray = response.data
    }
  }
  onSubmit() {
    this.$emit('submit')
  }
  onReset() {
    // Reset form validator on next tick because changes are still propagating
    this.$nextTick(() => {
      this.$refs.form.reset()
    })
    showInfoNotification({ content: 'Campi azzerati' })
    this.$emit('reset')
  }
  onCancel() {
    this.$emit('cancel')
  }
  onFiscalCodeEnterKeyPress() {
    this.$refs.formSurname.fireFocus(true)
  }

  // Watchers
  @Watch('form.fiscal_code')
  onFiscalCodeChanged(val: string, oldVar: string) {
    if (oldVar === undefined) return
    if (!val || val.length != 16 || Validator.codiceFiscale(val).invalid) return
    const personalInfo = Parser.cfDecode(val)
    const parsedPlace = Parser.cfToBirthPlace(val)
    this.form.gender = personalInfo.gender
    this.form.birth_date = personalInfo.date?.toISOString()
    this.form.birth_place = personalInfo.place
    this.form.birth_province = parsedPlace?.province
  }
}
