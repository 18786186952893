






















































import { Vue, Component, Prop, PropSync } from 'vue-property-decorator'
import { ValidationObserver } from 'vee-validate'
import { showInfoNotification } from '../../utils/notification'
import InputFieldWithValidation from '@/components/Eterian/form/InputFieldWithValidation.vue'

@Component({
  components: {
    ValidationObserver,
    InputFieldWithValidation,
  },
})
export default class OperatorForm extends Vue {
  $refs!: {
    form: InstanceType<typeof ValidationObserver>
  }

  // Properties
  @Prop({ type: Boolean, default: false })
  isUpdateForm!: boolean
  @PropSync('formData', { type: Object, required: true })
  form!: Record<string, any>

  // Data
  confirmPassword = ''

  // Computed Data
  get submitBtnText() {
    if (this.isUpdateForm) return 'Modifica'
    else return 'Inserisci'
  }

  // Methods
  onSubmit() {
    this.$emit('submit')
  }
  onReset() {
    // Reset form validator on next tick because changes are still propagating
    this.$nextTick(() => {
      this.$refs.form.reset()
    })
    showInfoNotification({ content: 'Campi azzerati' })
    this.$emit('reset')
  }
  onCancel() {
    this.$emit('cancel')
  }
}
