



























































import { Vue, Component } from 'vue-property-decorator'
import DynamicSection from '@/components/Eterian/DynamicSection.vue'
import CardComponent from '@/components/CardComponent.vue'
import TitleBar from '@/components/Eterian/TitleBar.vue'
import { SettingsStore } from '@/store/modules/settings'
import { AppStore } from '@/store/modules/app'
import { RoutePaths } from '@/router/route-details'

@Component({
  components: {
    DynamicSection,
    CardComponent,
    TitleBar,
  },
})
export default class Settings extends Vue {
  // Computed Data
  get titleStack() {
    return ['Amministrazione', 'Impostazioni']
  }
  get settings() {
    return SettingsStore.data
  }
  get containerFluidSwitch() {
    return AppStore.isContainerFluid
  }
  set containerFluidSwitch(value: boolean) {
    AppStore.containerFluidToggle(value)
  }

  // Vue Hooks
  async mounted() {
    await this.loadSettings()
  }

  // Methods
  async loadSettings() {
    await SettingsStore.actionGet()
  }
  routeToUpdateSettings() {
    if (!this.settings) return
    this.$router.push(RoutePaths.updateSettings())
  }
}
