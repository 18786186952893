

























































































































import { Vue, Component } from 'vue-property-decorator'
import CardComponent from '@/components/CardComponent.vue'
import TitleBar, { ITitleBarButton } from '@/components/Eterian/TitleBar.vue'
import { UserStore } from '@/store/modules/user'
import { loadStripe, openCustomerPortalSession } from '@/store/api/admin/billing'
import { RoutePaths } from '@/router/route-details'
import DynamicSection from '@/components/Eterian/DynamicSection.vue'
import { SettingsStore } from '@/store/modules/settings'
import { PaymentStore } from '@/store/modules/payment'
import CardButton from '@/components/Eterian/CardButton.vue'
import PaymentTable from './tables/PaymentTable.vue'
import Tiles from '@/components/Eterian/Tiles.vue'
import TextInfo from '@/components/Eterian/TextInfo.vue'
import { IProductPlan } from '@/store/interfaces/billing-model'
import ProductPlanTable from './tables/ProductPlanTable.vue'

@Component({
  components: {
    DynamicSection,
    CardComponent,
    TitleBar,
    CardButton,
    PaymentTable,
    Tiles,
    TextInfo,
    ProductPlanTable,
  },
})
export default class Billing extends Vue {
  // Data
  isLoading = false

  // Computed Data
  get titleStack() {
    return ['Amministrazione', 'Fatturazione']
  }
  get titleBarButton() {
    return {
      label: 'Home',
      icon: 'arrow-left',
      to: '/main/dashboard',
    } as ITitleBarButton
  }
  get companyProfile() {
    return UserStore.companyProfile
  }
  get companyEmail() {
    if (!UserStore.userProfile?.is_superuser) return UserStore.userProfile?.email
    return ''
  }
  get settings() {
    return SettingsStore.data
  }
  get productPlans() {
    return PaymentStore.productPlans
  }
  get productPlanDict() {
    return PaymentStore.productPlanDict
  }
  get payments() {
    return PaymentStore.data
  }
  get isSubscribed() {
    const status = this.settings?.subscription_status
    return status == 'active' || status == 'past_due'
  }
  get isPaymentPending() {
    const status = this.settings?.subscription_status
    return status == 'past_due'
  }
  get subscribedPlanName() {
    if (this.settings?.plan_id) {
      const plan: IProductPlan = this.productPlanDict[this.settings.plan_id]
      if (plan) return `${plan.name} (${plan.interval})`
    }
    return ''
  }
  get stripeResult() {
    return this.$route.query.result
  }
  get resultNotification() {
    if (this.stripeResult == 'cancel')
      return {
        type: 'is-danger',
        text: ' Si è verificato un errore durante la procedura di acquisto. Si prega di riprovare.',
        subtext: 'Se il problema dovesse persistere, si prega di aprire un ticket per ricevere assistenza.',
      }
    else if (this.stripeResult == 'success')
      return {
        type: 'is-success',
        text: ' Acquisto completato con successo! Utilizza subito il tuo nuovo abbonamento a Referto In Cloud.',
        subtext: 'Buon lavoro!',
      }
    return null
  }

  // Vue Hooks
  created() {
    loadStripe()
  }
  async mounted() {
    await this.loadCompanyProfile()
    await this.loadSettings()
    await this.loadPayments()
    await this.checkForSubscriptionConfirmation()
  }

  // Methods
  async loadCompanyProfile() {
    await UserStore.actionGetCompanyProfile()
  }
  async loadSettings() {
    await SettingsStore.actionGet()
  }
  async loadPayments() {
    this.isLoading = true
    await PaymentStore.actionGetAll()
    this.isLoading = false
  }
  async checkForSubscriptionConfirmation() {
    if (!this.$route.query.session_id || this.isSubscribed) return
    await new Promise((resolve) => setTimeout(resolve, 1000))
    while (!this.isSubscribed) {
      await this.loadSettings()
      await new Promise((resolve) => setTimeout(resolve, 2500))
    }
    await this.loadPayments()
  }
  routeToUpdateCompanyProfile() {
    if (!this.companyProfile) return
    this.$router.push(RoutePaths.updateCompanyMe())
  }
  async customerPortal() {
    await openCustomerPortalSession()
  }
}
