





















import { Vue, Component, Prop } from 'vue-property-decorator'
import TitleBar from '@/components/Eterian/TitleBar.vue'
import CardComponent from '@/components/CardComponent.vue'
import ModalBox from '@/components/Eterian/ModalBox.vue'
import ExamForm from '@/views/forms/ExamForm.vue'
import { IExamUpdate, ExamUtils } from '@/store/interfaces/exam-model'
import { ExamStore } from '@/store/modules/exam'
import DynamicSection from '@/components/Eterian/DynamicSection.vue'

@Component({
  components: {
    CardComponent,
    TitleBar,
    ModalBox,
    ExamForm,
    DynamicSection,
  },
})
export default class UpdateExam extends Vue {
  // Properties
  @Prop({ type: Number, required: true })
  id!: number

  // Data
  isModalActive = false

  // Computed Data
  get titleStack() {
    return ['Amministrazione', 'Esami', 'Modifica']
  }
  get exam() {
    return ExamStore.current
  }
  get form(): IExamUpdate {
    return ExamUtils.updateInit(this.exam)
  }

  // Vue Hooks
  async mounted() {
    await this.loadExam()
  }

  // Methods
  async loadExam() {
    if (this.exam && this.exam.id == this.id) return
    await ExamStore.actionGet(this.id)
  }
  async onSubmit() {
    if (!this.exam) return
    const diffObj = ExamUtils.updateGetDiff(this.form, this.exam)
    const result = await ExamStore.actionUpdate({ id: this.id, obj: diffObj })
    if (result) {
      this.$router.back()
    }
  }
  onCancel() {
    if (!this.exam) return
    if (ExamUtils.updateCheckHasChanged(this.form, this.exam)) {
      this.isModalActive = true
    } else {
      this.$router.back()
    }
  }
}
