







































import { Vue, Component, PropSync } from 'vue-property-decorator'
import ModalBoxForm from '@/components/Eterian/ModalBoxForm.vue'
import InputFieldWithValidation from '@/components/Eterian/form/InputFieldWithValidation.vue'
import { ExamTypeStore } from '@/store/modules/exam-type'
import { ExamTypeUtils, IExamTypeCreate } from '@/store/interfaces/exam-type-model'

@Component({
  components: {
    ModalBoxForm,
    InputFieldWithValidation,
  },
})
export default class AddExamType extends Vue {
  // Properties
  @PropSync('isActive', { type: Boolean, required: true })
  isModalActive!: boolean

  // Data
  form: IExamTypeCreate = ExamTypeUtils.createInit()

  // Methods
  resetModal() {
    this.isModalActive = false
    this.form = ExamTypeUtils.createInit()
  }
  modalCancel() {
    this.resetModal()
    this.$emit('op-aborted')
  }
  async modalConfirm() {
    // Check if undefined field has been modified to empty string
    ExamTypeUtils.createCleanUndefined(this.form)
    await ExamTypeStore.actionCreate(this.form)
    this.resetModal()
    this.$emit('op-confirmed')
  }
}
