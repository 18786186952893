


















import { Vue, Component, Prop } from 'vue-property-decorator'
import TitleBar from '@/components/Eterian/TitleBar.vue'
import CardComponent from '@/components/CardComponent.vue'
import ModalBox from '@/components/Eterian/ModalBox.vue'
import PatientForm from '@/views/forms/PatientForm.vue'
import { IPatientUpdate, PatientUtils } from '@/store/interfaces/patient-model'
import { PatientStore } from '@/store/modules/patient'
import DynamicSection from '@/components/Eterian/DynamicSection.vue'

@Component({
  components: {
    CardComponent,
    TitleBar,
    ModalBox,
    PatientForm,
    DynamicSection,
  },
})
export default class UpdatePatient extends Vue {
  // Properties
  @Prop({ type: Number, required: true })
  id!: number

  // Data
  isModalActive = false
  form: IPatientUpdate = {}

  // Computed Data
  get titleStack() {
    return ['Amministrazione', 'Pazienti', 'Modifica']
  }
  get patient() {
    return PatientStore.current
  }

  // Vue Hooks
  async mounted() {
    await this.loadPatient()
  }

  // Methods
  async loadPatient() {
    if (!this.patient || this.patient.id != this.id) {
      await PatientStore.actionGet(this.id)
    }
    this.form = PatientUtils.updateInit(this.patient)
  }
  async onSubmit() {
    if (!this.patient) return
    const diffObj = PatientUtils.updateGetDiff(this.form, this.patient)
    const result = await PatientStore.actionUpdate({ id: this.id, obj: diffObj })
    if (result) {
      this.$router.back()
    }
  }
  onCancel() {
    if (!this.patient) return
    if (PatientUtils.updateCheckHasChanged(this.form, this.patient)) {
      this.isModalActive = true
    } else {
      this.$router.back()
    }
  }
}
