

























import { Vue, Component } from 'vue-property-decorator'
import TitleBar from '@/components/Eterian/TitleBar.vue'
import CardComponent from '@/components/CardComponent.vue'
import ModalBox from '@/components/Eterian/ModalBox.vue'
import CompanyForm from '@/views/forms/CompanyForm.vue'
import { ICompanyUpdate, ICompanyFirstUpdate, CompanyUtils } from '@/store/interfaces/company-model'
import { UserStore } from '@/store/modules/user'
import DynamicSection from '@/components/Eterian/DynamicSection.vue'

@Component({
  components: {
    CardComponent,
    TitleBar,
    ModalBox,
    CompanyForm,
    DynamicSection,
  },
})
export default class UpdateCompanyMe extends Vue {
  // Data
  isModalActive = false

  // Computed Data
  get titleStack() {
    return ['Amministrazione', 'Dati di Fatturazione', 'Modifica']
  }
  get companyMe() {
    return UserStore.companyProfile
  }
  get isFirstUpdate() {
    if (this.companyMe) return !this.companyMe.is_completed
    return false
  }
  get form(): ICompanyFirstUpdate | ICompanyUpdate {
    if (this.isFirstUpdate) return CompanyUtils.firstUpdateInit(this.companyMe)
    return CompanyUtils.updateInit(this.companyMe)
  }

  // Vue Hooks
  async mounted() {
    await this.loadCompany()
  }

  // Methods
  async loadCompany() {
    if (this.companyMe) return
    await UserStore.actionGetCompanyProfile()
  }

  async onSubmit() {
    if (!this.companyMe) return
    let result
    if (this.isFirstUpdate) {
      const diffObj = CompanyUtils.firstUpdateGetDiff(this.form as ICompanyFirstUpdate, this.companyMe)
      result = await UserStore.actionCompleteCompanyProfile(diffObj)
    } else {
      const diffObj = CompanyUtils.updateGetDiff(this.form, this.companyMe)
      result = await UserStore.actionUpdateCompanyProfile(diffObj)
    }
    if (result) {
      this.$router.back()
    }
  }
  onCancel() {
    if (!this.companyMe) return
    let hasChanged
    if (this.isFirstUpdate)
      hasChanged = CompanyUtils.firstUpdateCheckHasChanged(this.form as ICompanyFirstUpdate, this.companyMe)
    else hasChanged = CompanyUtils.updateCheckHasChanged(this.form, this.companyMe)

    if (hasChanged) {
      this.isModalActive = true
    } else {
      this.$router.back()
    }
  }
}
