

































import { Vue, Component, Prop } from 'vue-property-decorator'
import { UserStore } from '@/store/modules/user'
import DynamicSection from '@/components/Eterian/DynamicSection.vue'

@Component({
  components: {
    DynamicSection,
  },
})
export default class HeroBarMain extends Vue {
  // Properties
  @Prop({ type: Number, required: true })
  examsDueToday!: number
  @Prop({ type: Number, required: true })
  examsExpired!: number
  @Prop({ type: Number, required: true })
  examsWithoutDeadline!: number

  // Computed Data
  get userName() {
    return UserStore.userProfile?.full_name
  }
  get isAdministrator() {
    return UserStore.hasAdminAccess
  }

  // Methods
  declineExamWord(quantity: number) {
    if (quantity == 1) return 'esame'
    return 'esami'
  }
}
