












































































import { Vue, Component } from 'vue-property-decorator'
import PatientTable from './tables/PatientTable.vue'
import CardComponent from '@/components/CardComponent.vue'
import ModalBox from '@/components/Eterian/ModalBox.vue'
import TitleBar, { ITitleBarButton } from '@/components/Eterian/TitleBar.vue'
import CardButton from '@/components/Eterian/CardButton.vue'
import CardToolbar from '@/components/CardToolbar.vue'
import { showInfoNotification } from '../../utils/notification'
import { PatientStore } from '@/store/modules/patient'
import { AppStore } from '@/store/modules/app'
import DynamicSection from '@/components/Eterian/DynamicSection.vue'

@Component({
  components: {
    DynamicSection,
    CardToolbar,
    CardButton,
    TitleBar,
    CardComponent,
    PatientTable,
    ModalBox,
  },
})
export default class Patients extends Vue {
  // Data
  isLoading = false
  searchString = ''
  isModalActive = false
  isPasswordResetModalActive = false
  trashObject: Record<string, any> | null = null

  loadMoreEnabled = true
  dataSkip = 0
  dataLimit = 15

  // Computed Data
  get titleStack() {
    return ['Amministrazione', 'Pazienti']
  }
  get titleBarButton() {
    return {
      label: 'Aggiungi un Paziente',
      icon: 'account-plus',
      to: '/main/patients/add',
    } as ITitleBarButton
  }
  get filterString() {
    return AppStore.patientFilterString
  }
  set filterString(value: string) {
    AppStore.SET_PATIENT_FILTER_STRING(value)
  }
  get patients() {
    if (this.filterString) {
      return PatientStore.filteredData
    }
    return PatientStore.data
  }
  get showLoadMoreButton() {
    return this.filterString ? false : this.loadMoreEnabled
  }
  get trashObjectName() {
    if (this.trashObject) {
      return this.trashObject.name + ' ' + this.trashObject.surname
    }
    return null
  }

  // Vue Hooks
  async mounted() {
    await this.loadPatients()
  }
  destroyed() {
    this.filterString = ''
  }

  // Methods
  async loadPatients(): Promise<number | null> {
    this.isLoading = true
    const result = await PatientStore.actionGetMulti({ skip: this.dataSkip, limit: this.dataLimit })
    if (result != null) {
      if (result < this.dataLimit) {
        this.loadMoreEnabled = false
      }
      this.dataSkip += result
    }
    this.isLoading = false
    return result
  }

  async refreshPatients(notify = true) {
    this.isLoading = true
    let result
    if (this.filterString) {
      result = await PatientStore.actionSearch(this.filterString)
    } else {
      let computedDataSkip = this.dataSkip
      if (!this.loadMoreEnabled) {
        computedDataSkip += this.dataLimit
      }
      result = await PatientStore.actionGetMulti({ limit: computedDataSkip })
    }
    if (result && notify) showInfoNotification({ content: 'Aggiornato' })
    this.isLoading = false
  }
  async searchPatients() {
    if (this.searchString) {
      this.isLoading = true
      const result = await PatientStore.actionSearch(this.searchString)
      if (result) {
        this.filterString = this.searchString
        this.searchString = ''
        document.getElementById('searchField')?.blur()
      }
      this.isLoading = false
    }
  }
  resetSearchForm() {
    if (this.filterString) {
      this.isLoading = true
      this.searchString = ''
      this.filterString = ''
      this.isLoading = false
    }
  }

  trashModal(trashObject: Record<string, any>) {
    this.trashObject = trashObject
    this.isModalActive = true
  }
  async trashConfirm() {
    this.isModalActive = false
    await this.deletePatient()
  }
  async deletePatient() {
    if (this.trashObject) {
      const result = await PatientStore.actionDelete(this.trashObject.id)
      if (result) {
        await this.refreshPatients(false)
      }
    }
  }
  async downloadPatientPrivacy(id: number) {
    await PatientStore.actionGetPatientPrivacy(id)
  }
  async resetPatientPassword(id: number, fullName: string, isActive: boolean) {
    if (!isActive) {
      await PatientStore.actionResetPatientPassword({ id: id, mustChangePassword: false })
      return
    }
    this.$buefy.dialog.confirm({
      title: 'Reset Password Paziente',
      message: `Sei sicuro di voler <b>resettare</b> la password del paziente <b>${fullName}</b>?<br>Ricorda di comunicare al paziente la nuova password generata.`,
      confirmText: 'Resetta la Password',
      cancelText: 'Annulla',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: async () => await PatientStore.actionResetPatientPassword({ id: id, mustChangePassword: false }),
    })
  }
}
