




















import { Vue, Component } from 'vue-property-decorator'
import TitleBar, { ITitleBarButton } from '@/components/Eterian/TitleBar.vue'
import CardComponent from '@/components/CardComponent.vue'
import ModalBox from '@/components/Eterian/ModalBox.vue'
import PatientForm from '@/views/forms/PatientForm.vue'
import { IPatientCreate, PatientUtils } from '@/store/interfaces/patient-model'
import { PatientStore } from '@/store/modules/patient'
import DynamicSection from '@/components/Eterian/DynamicSection.vue'

@Component({
  components: {
    CardComponent,
    TitleBar,
    ModalBox,
    PatientForm,
    DynamicSection,
  },
})
export default class AddPatient extends Vue {
  // Data
  isModalActive = false
  form: IPatientCreate = PatientUtils.createInit()

  // Computed Data
  get titleStack() {
    return ['Amministrazione', 'Pazienti', 'Inserisci']
  }
  get titleBarButton() {
    return {
      label: 'Torna ai Pazienti',
      icon: 'arrow-left',
      to: '/main/patients',
    } as ITitleBarButton
  }

  // Methods
  async onSubmit() {
    // Check if undefined field has been modified to empty string
    PatientUtils.createCleanUndefined(this.form)
    const result = await PatientStore.actionCreate(this.form)
    if (result) {
      this.$router.back()
    }
  }
  onReset() {
    this.form = PatientUtils.createInit()
  }
  onCancel() {
    if (PatientUtils.createCheckHasChanged(this.form)) {
      this.isModalActive = true
    } else {
      this.$router.back()
    }
  }
}
