






























import { Vue, Component } from 'vue-property-decorator'
import OperatorTable from './tables/OperatorTable.vue'
import CardComponent from '@/components/CardComponent.vue'
import ModalBox from '@/components/Eterian/ModalBox.vue'
import TitleBar, { ITitleBarButton } from '@/components/Eterian/TitleBar.vue'
import CardButton from '@/components/Eterian/CardButton.vue'
import CardToolbar from '@/components/CardToolbar.vue'
import { OperatorStore } from '@/store/modules/operator'
import DynamicSection from '@/components/Eterian/DynamicSection.vue'

@Component({
  components: {
    CardToolbar,
    CardButton,
    TitleBar,
    CardComponent,
    OperatorTable,
    ModalBox,
    DynamicSection,
  },
})
export default class Operators extends Vue {
  // Data
  isLoading = false
  isModalActive = false
  trashObject: Record<string, any> | null = null

  // Computed Data
  get titleStack() {
    return ['Amministrazione', 'Operatori']
  }
  get titleBarButton() {
    return {
      label: 'Aggiungi un Operatore',
      icon: 'account-plus',
      to: '/main/operators/add',
    } as ITitleBarButton
  }
  get operators() {
    return OperatorStore.data
  }
  get trashObjectName() {
    if (this.trashObject) {
      return this.trashObject.full_name + ' (' + this.trashObject.email + ')'
    }
    return null
  }

  // Vue Hooks
  async mounted() {
    await this.loadOperators()
  }

  // Methods
  async loadOperators() {
    this.isLoading = true
    await OperatorStore.actionGetAll()
    this.isLoading = false
  }
  trashModal(trashObject: Record<string, any>) {
    this.trashObject = trashObject
    this.isModalActive = true
  }
  async trashConfirm() {
    this.isModalActive = false
    await this.deleteOperator()
  }
  async deleteOperator() {
    if (this.trashObject) {
      const result = await OperatorStore.actionDelete(this.trashObject.id)
      if (result) {
        await this.loadOperators()
      }
    }
  }
}
