





























































import { IExamType } from '@/store/interfaces/exam-type-model'
import { ExamTypeStore } from '@/store/modules/exam-type'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class ExamTypeTable extends Vue {
  // Properties
  @Prop({ type: Array, required: true })
  dataIn!: Array<Record<string, any>>
  @Prop({ type: Boolean, default: false })
  checkable!: boolean
  @Prop({ type: Boolean, default: false })
  isLoading!: boolean

  // Data
  defaultSort = 'name'
  stickyHeader = false
  perPage = 12
  checkedRows = []

  // Computed Data
  get paginated() {
    if (this.dataIn.length > this.perPage) return true
    return false
  }

  // Methods
  onEditButtonClick(row: Record<string, any>) {
    ExamTypeStore.SET_CURRENT(row as IExamType)
    this.$emit('edit-clicked')
  }
  onDeleteButtonClick(row: Record<string, any>) {
    this.$emit('delete-clicked', row)
  }
}
