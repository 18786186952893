







































































import { Vue, Component } from 'vue-property-decorator'
import ExamTypeTable from './tables/ExamTypeTable.vue'
import AddExamType from './modals/AddExamType.vue'
import UpdateExamType from './modals/UpdateExamType.vue'
import CardComponent from '@/components/CardComponent.vue'
import ModalBox from '@/components/Eterian/ModalBox.vue'
import TitleBar, { ITitleBarButton } from '@/components/Eterian/TitleBar.vue'
import CardButton from '@/components/Eterian/CardButton.vue'
import CardToolbar from '@/components/CardToolbar.vue'
import { ExamTypeStore } from '@/store/modules/exam-type'
import DynamicSection from '@/components/Eterian/DynamicSection.vue'
import { showInfoNotification } from '@/utils/notification'

@Component({
  components: {
    CardToolbar,
    CardButton,
    TitleBar,
    CardComponent,
    ExamTypeTable,
    ModalBox,
    AddExamType,
    UpdateExamType,
    DynamicSection,
  },
})
export default class ExamTypes extends Vue {
  // Data
  isLoading = false
  isModalActive = false
  isCreateModalFormActive = false
  isUpdateModalFormActive = false
  trashObject: Record<string, any> | null = null

  searchString = ''
  filterString = ''

  // Computed Data
  get titleStack() {
    return ['Amministrazione', "Tipologie d'Esame"]
  }
  get titleBarButton() {
    return {
      label: "Aggiungi Tipologia d'Esame",
      icon: 'tag-plus',
    } as ITitleBarButton
  }
  get examTypes() {
    if (this.filterString) {
      return ExamTypeStore.filteredData
    }
    return ExamTypeStore.data
  }
  get trashObjectName() {
    if (this.trashObject) {
      return this.trashObject.name
    }
    return null
  }
  get currentExamTypeId() {
    if (ExamTypeStore.current) return ExamTypeStore.current.id
    return 0
  }

  // Vue Hooks
  async mounted() {
    await this.loadExamTypes()
  }

  // Methods
  async loadExamTypes() {
    this.isLoading = true
    await ExamTypeStore.actionGetAll()
    if (this.filterString) {
      ExamTypeStore.actionStaticSearch(this.filterString)
    }
    this.isLoading = false
  }
  async refreshExamTypes() {
    await this.loadExamTypes()
    showInfoNotification({ content: 'Aggiornato' })
  }
  async searchExamTypes() {
    if (this.searchString) {
      this.isLoading = true
      ExamTypeStore.actionStaticSearch(this.searchString)
      this.filterString = this.searchString
      this.searchString = ''
      document.getElementById('searchField')?.blur()
      this.isLoading = false
    }
  }
  resetSearchForm() {
    if (this.filterString) {
      this.isLoading = true
      this.searchString = ''
      this.filterString = ''
      this.isLoading = false
    }
  }
  trashModal(trashObject: Record<string, any>) {
    this.trashObject = trashObject
    this.isModalActive = true
  }
  async trashConfirm() {
    this.isModalActive = false
    await this.deleteExamType()
  }
  async deleteExamType() {
    if (this.trashObject) {
      const result = await ExamTypeStore.actionDelete(this.trashObject.id)
      if (result) {
        await this.loadExamTypes()
      }
    }
  }
  createModalFormOpen() {
    this.isCreateModalFormActive = true
  }
  updateModalFormOpen() {
    this.isUpdateModalFormActive = true
  }
  async examTypeCreated() {
    await this.loadExamTypes()
  }
  async examTypeUpdated() {
    await this.loadExamTypes()
  }
}
