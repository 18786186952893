
































import { Vue, Component, Prop, PropSync } from 'vue-property-decorator'
import { ValidationObserver } from 'vee-validate'

interface ITitleIcon {
  type?: string
  icon: string
}

interface IModalButton {
  type?: 'is-danger' | 'is-info' | 'is-success' | 'is-warning'
  text: string
}

@Component({
  components: {
    ValidationObserver,
  },
})
export default class ModalBoxForm extends Vue {
  // Properties
  @PropSync('isActive', { type: Boolean, required: true })
  isModalActive!: boolean
  @Prop({ type: String, default: 'Conferma Azione' })
  title: string | undefined
  @Prop(Object)
  titleIcon: ITitleIcon | undefined
  @Prop({ type: Object, default: (): IModalButton => ({ type: 'is-warning', text: 'Conferma' }) })
  confirmBtn!: IModalButton
  @Prop({ type: Object, default: (): IModalButton => ({ text: 'Annulla' }) })
  cancelBtn!: IModalButton

  // Methods
  cancel() {
    this.isModalActive = false
    this.$emit('cancel')
  }
  confirm() {
    this.$emit('confirm')
  }
}
